<div class="login">
    <div class="text-center">
        <img src="assets/img/logo.png" />
    </div>
    <h3 class="text-center">Acesse o sistema</h3>
    <div #alert class="alert alert-danger alert-dismissible fade show" role="alert" [ngStyle]="{'display' : (errors.length > 0 ? 'block' : 'none')}">
        <strong>Ops!</strong>
        <div *ngFor="let error of errors"><strong>! </strong>{{ error }}</div>
        <button type="button" class="close" aria-label="Close">
          <span aria-hidden="true" (click)="alertClose()">&times;</span>
        </button>
    </div>
    <form>
        <input #inputCompany (keydown)="$event.keyCode == 13 ? inputUsername.focus(): null" [(ngModel)]="customer" type="text" name="c" placeholder="Empresa" required="required" />
        <input #inputUsername (keydown)="$event.keyCode == 13 ? inputPassword.focus():null" [(ngModel)]="username" type="text" name="u" placeholder="Usuário" required="required" />
        <input #inputPassword (keydown)="$event.keyCode == 13 ? btnEnter.click():null" [(ngModel)]="password" type="password" name="p" placeholder="Senha" required="required" />
        <button #btnEnter (click)="enter()" type="button" class="btn btn-primary btn-block btn-large">Entrar</button>
    </form>
</div>