import { HttpClient } from '@angular/common/http';
import { AuthService } from './../services/auth.service';
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  customer = '';
  username = '';
  password = '';
  errors = [];
  public receivedMessages: string[] = [];

  subscriptions: Subscription[] = [];

  private enterprise:string = '';

  @ViewChild('inputCompany') inputCompany: ElementRef;
  @ViewChild('inputCompany') inputUsername: ElementRef;
  @ViewChild('alert') alert: ElementRef;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    if (environment.production) {

      // HACK: Don't log to console in production environment.
      // TODO: This can be done in better way using logger service and logger factory.
      if (window) {
        window.console.log = window.console.warn = window.console.info = function () {
          // Don't log anything.
        };
      }
    }

    //refaz a consulta na mesma URL para obter os headers e o atributo empresa em x-enterprise
    let request = new XMLHttpRequest();
    request.open('GET', window.location.origin, false);
    request.send(null);

    let header = request.getResponseHeader("x-enterprise")

    //verifica se tem o parameto com a empresa
    let routeParams = this.route.snapshot.paramMap;
    let param = routeParams.get('enterprise');

    //checa se existe um dos dois dando preferencia para o header
    this.enterprise = header !== null ? header : param;
    console.log("Empresa: " + this.enterprise);
  }

  ngAfterViewInit() {
    if(this.enterprise){
      this.customer = this.enterprise;
      this.inputCompany.nativeElement.setAttribute("type", "hidden");
      this.inputUsername.nativeElement.focus();
    }else{
      this.inputCompany.nativeElement.focus();
    }
  }

  enter() {
    this.errors = [];

    if (!this.username) {
      this.errors.push('Informe o usuário');
    }

    if (!this.password) {
      this.errors.push('Informe a senha');
    }

    if (!this.customer) {
      this.errors.push('Informe a empresa');
    }

    if (this.errors.length == 0) {
      this.authService.setUserName(this.username);
      this.authService
        .auth(this.customer, this.username, this.password)
        .subscribe(
          (resp) => {
            if (resp) {
              this.router.navigate(['home']);
            }
          },
          (error) => {
            console.log(error);
            this.errors.push('Usuário/senha inválidos');
          }
        );
    } else {
      this.inputCompany.nativeElement.focus();
    }
  }

  alertClose() {
    this.errors = [];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
