import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnChanges {
  @Input() toastTitle: String = '';
  @Input() toastMessageFiles: ToastMessageFile[] = [];

  private toastHtmlElement: any;
  private toastShow: boolean = true;

  constructor() { }

  ngOnInit() {
    this.toastHtmlElement = document.querySelector("#toast");
    this.toastHtmlElement.style.display = "none";
  }

  //https://stackoverflow.com/questions/65408155/problems-with-ngonchanges-when-updating-an-array
  //onChanges só é disparado quando muda o objeto, adicionar ou remover elementos no mesmo objeto(array) não dispara o evento
  //nesse caso eu mudo o objeto string para disparar o evento
  ngOnChanges(changes: SimpleChanges) {
    if (this.toastHtmlElement) {
      if (this.toastMessageFiles.length > 0) {
        if (this.toastShow) {
          this.toastHtmlElement.style.display = "block";
        }else{
          this.toastHtmlElement.style.display = "none";
        }
      } else {
        //caso o objeto pai use o mesmo toast, qdo zerar a lista reativa o toastshow.
        this.toastShow = true;
        this.toastHtmlElement.style.display = "none";
      }
    }
  }

  show() {
    if (this.toastHtmlElement) {
      this.toastShow = true;
      this.toastHtmlElement.style.dispay = "block";
    }
  }

  close() {
    if (this.toastHtmlElement) {
      this.toastShow = false;
      this.toastHtmlElement.style.dispay = "none";
    }
  }
}

export class ToastMessageFile {
  name: String;
  progress: String;
  isDone: boolean; // 0 = in progress, 1 = done
  isUpload: boolean; // 0 = download, 1 = upload
}
